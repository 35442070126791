@switch (state.$type) {
  @case ("loading") {
    <div class="publications-grid">
      @for (item of EmptyArray; track item; let i = $index) {
        <app-publication-tile-placeholder />
      }
    </div>
  }

  @case ("error") {
    <app-reload
      textKey="shared.errorLoadingTheList"
      (action)="updatePublications()"
    />
  }

  @case ("no-results") {
    <app-partner-calendar-acceptance-banner
      (calendarAccepted)="updatePublications()"
    />
    <app-reload
      textKey="campaign.content.noResultsFound"
      buttonKey="campaign.content.resetSearch"
      (action)="resetFilters.emit()"
    />
  }

  @case ("list") {
    <app-partner-publication-template-aggregated-kpis [kpis]="state.kpis" />
    <app-partner-calendar-acceptance-banner
      (calendarAccepted)="updatePublications()"
    />
    <div class="publications-grid">
      @for (
        item of state.publicationTemplates;
        track item.publicationTemplate.id
      ) {
        <app-publication-tile [publication]="item" [areActionsVisible]="false">
          <app-publication-template-preview [data]="item.publicationTemplate" />
        </app-publication-tile>
      }
    </div>
    <app-paginator
      [currentPage]="currentPage()"
      [totalPages]="totalPages()"
      (pageChange)="currentPage.set($event)"
    />
  }
}
