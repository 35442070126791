<div
  class="thumbnail"
  [style.background-image]="'url(' + publication().thumbnailUrl + ')'"
></div>
<div class="details">
  <div class="platform">
    <img
      [src]="
        '/assets/images/icons/ic_' + publication().platform + 'Content.svg'
      "
      class="header-icon"
    />
    <span class="publication-type">
      {{
        "publicationTemplatePostContainer." +
          (publication().publicationTemplateType
            | caseTransform: "SnakeToCamel") | translate
      }}
    </span>
  </div>
  <div class="schedules">
    @for (range of publication().recommendedSchedules.ranges; track $index) {
      <app-publication-template-list-item-schedule [schedule]="range" />
    } @empty {
      <div class="date">
        {{ "publicationTemplate.schedule.notScheduledYet" | translate }}
      </div>
    }
    @if (publication().recommendedSchedules.areThereMore) {
      <div class="date">
        <a href="#">{{ "shared.showMore" | translate }}</a>
      </div>
    }
  </div>
  @if (publication().summary; as summary) {
    <div class="summary">
      <div class="chip">
        <span class="bold">{{ summary.published }}</span>
        {{ "publicationTemplate.status.PUBLISHED" | translate }}
      </div>
      <div class="chip">
        <span class="bold">{{ summary.scheduled }}</span>
        {{ "publicationTemplate.status.SCHEDULED" | translate }}
      </div>
      @if (summary.withIssues) {
        <div class="chip warning">
          {{ summary.withIssues }}
          {{ "publicationTemplate.status.ISSUES" | translate }}
        </div>
      }
      @if (summary.notAccepted) {
        <div class="chip warning">
          {{ summary.notAccepted }}
          {{ "publicationTemplate.status.NOT_ACCEPTED" | translate }}
        </div>
      }
    </div>
  }
</div>
