import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { Address } from "../../../shared/models/address";
import { UrlPrefixes } from "../../../shared/models/appData";
import { CampaignPartnerStatus } from "../../../shared/enums/campaign.enums";
import { Image } from "../../../shared/models/image";
import { Partner } from "../../../shared/models/partner";
import { PartnerCampaign } from "../../../shared/models/partnerCampaign";
import { PartnerCampaignDetails } from "../../../shared/models/partnerCampaignDetail";
import { PartnerCampaignService } from "../../../shared/services/api/partner-campaign.service";
import { PartnerService } from "../../../shared/services/api/partner.service";
import { DialogService } from "../../../shared/services/dialog.service";
import { getTranslatedCountriesSignal } from "../../../shared/services/language.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UrlFormatterService } from "../../../shared/services/url-formatter-service";
import { CustomFormValidators } from "../../../shared/validators/custom-form-validators";
import { PartnerUrl } from "../../partner.url";
import { PartnerDialogService } from "../../partner-dialog.service";
import { getAppDataSignal } from "../../../shared/services/api/app-data.service";

export interface PartnerStatus {
  readonly selected: boolean | string;
  readonly label: string;
  readonly iconClass: string;
  readonly iconName: string;
}

@Component({
  selector: "app-partner-campaign-summary",
  templateUrl: "./partner-campaign-summary.component.html",
  styleUrl: "./partner-campaign-summary.component.scss",
})
export class PartnerCampaignSummaryComponent
  implements OnInit, OnChanges, OnDestroy
{
  protected readonly countries =
    getTranslatedCountriesSignal(getAppDataSignal());
  protected readonly URL_VALIDATION_PATTERN =
    CustomFormValidators.URL_WITH_OBLIGATORY_NO_PROTOCOL_PATTERN;
  protected readonly UrlPrefixes = UrlPrefixes;
  protected campaign: PartnerCampaign;
  protected campaignDescription: SafeHtml = "-";
  protected campaignDetails?: PartnerCampaignDetails;
  protected currentImage: Image = new Image();
  protected isSliding = false;
  protected loading = false;
  protected saving = false;
  protected slideImage: Image = new Image();

  private canLeaveCampaign = false;
  protected partner: Partner;
  private selectedAddress?: Address;
  private subscriptions = new Subscription();

  constructor(
    private readonly dialogService: DialogService,
    private readonly notificationService: NotificationService,
    private readonly partnerCampaignService: PartnerCampaignService,
    private readonly partnerDialogService: PartnerDialogService,
    private readonly partnerService: PartnerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly urlFormatterService: UrlFormatterService,
  ) {
    this.partner = this.partnerService.currentPartner;
    this.campaign = this.partnerCampaignService.currentCampaign!;
  }

  public ngOnChanges({ campaign }: SimpleChanges): void {
    if (campaign) {
      this.initData(this.campaign);
    }
  }

  public ngOnInit(): void {
    this.loading = true;
    this.initData(this.campaign);
    this.subscriptions.add(
      this.partnerCampaignService.partnerCampaignUpdated.subscribe(
        (campaign: PartnerCampaign) => this.initData(campaign),
      ),
    );

    this.subscriptions.add(
      this.route.queryParams.subscribe((params) => {
        if (params["fb-ads-connection-dialog"]) {
          this.partnerDialogService.showPartnerAuthorizeFacebookAds({
            partner: this.partner,
          });
        }
      }),
    );

    this.loading = false;
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  private initData(campaign: PartnerCampaign) {
    this.campaign = campaign;
    this.selectedAddress = this.campaign.partnerDefaultAddress;
    const isCampaignCancelled =
      campaign.campaignPartnerStatus === CampaignPartnerStatus.Cancelled;
    this.canLeaveCampaign = !isCampaignCancelled && campaign.canPartnerLeave;
    this.updateCampaignDetails(campaign.currentDetails);
  }

  private updateCampaignDetails(
    campaignDetails?: PartnerCampaignDetails,
  ): void {
    this.campaignDetails = campaignDetails;
    this.campaignDescription = this.sanitizer.bypassSecurityTrustHtml(
      this.campaignDetails?.description || "-",
    );

    if (this.campaignDetails && this.campaignDetails.sliders.length > 0) {
      this.currentImage = this.campaignDetails.sliders[0];
      this.slideImage = this.campaignDetails.sliders[0];

      this.campaignDetails.sliders.forEach((image) => {
        image.file.replace(
          "http://res.cloudinary.com",
          "https://res.cloudinary.com",
        );
      });
    }
  }

  protected showImage(index: number): void {
    // Only allow changing image if the slider if idle
    if (this.isSliding) {
      return;
    }

    const currentImageArray =
      this.campaignDetails?.sliders.filter((image, idx) => idx === index) ?? [];

    if (currentImageArray.length > 0) {
      this.isSliding = true;
      this.slideImage = currentImageArray[0];

      setTimeout(() => {
        this.currentImage = this.slideImage;
        this.isSliding = false;
      }, 500);
    }
  }

  protected nextImage(): void {
    const sliders = this.campaignDetails?.sliders ?? [];
    const currentImageIndex = sliders.findIndex(
      (image) => image.file === this.currentImage.file,
    );

    if (!currentImageIndex || currentImageIndex === sliders.length - 1) {
      this.showImage(0);
    } else {
      this.showImage(currentImageIndex + 1);
    }
  }

  protected goToPartnerSettings(): void {
    this.router.navigate(["/partner/" + this.partner.id + "/edit"]);
  }

  protected checkLeaveLink(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target.classList.contains("leave-link")) {
      this.showLeaveCampaignConfirmation();
    }
  }

  private async showLeaveCampaignConfirmation(): Promise<void> {
    this.dialogService.confirm({
      title: "partner.campaign.summary.leaveCampaign",
      message: this.canLeaveCampaign
        ? "partner.campaign.summary.leaveCampaignConfirmTitle"
        : "partner.campaign.summary.leaveCampaignWithPendingPosts",
      onConfirm: () => this.leaveCampaign(),
    });
  }

  private leaveCampaign(): void {
    this.partnerCampaignService
      .cancelCampaign(this.partnerService.currentPartnerId, this.campaign.id)
      .subscribe({
        next: () => {
          this.router.navigate([PartnerUrl.Home(this.partner.id)]);
        },
        error: () => {
          this.notificationService.error("shared.changesCouldNotBeSaved");
        },
      });
  }

  protected saveCampaignDetails(): void {
    this.saving = true;
    this.partnerService
      .updateCampaignPartner(
        this.partner.id,
        this.campaign.id,
        this.campaign.partner,
      )
      .subscribe({
        next: (campaign: PartnerCampaign) => {
          this.saving = false;
          this.campaign.partner.salesRep = campaign.partner.salesRep;
          this.campaign.partner.postsAdditionalText =
            campaign.partner.postsAdditionalText;
          this.campaign.partner.customFieldValue =
            campaign.partner.customFieldValue;
          this.campaign.partner.defaultAddressId =
            campaign.partner.defaultAddressId;
          this.campaign.partner.shortUrl = campaign.partner.shortUrl;
          this.campaign.partner.shortUrlPrefix =
            campaign.partner.shortUrlPrefix;
          this.notificationService.success("shared.changesSavedSuccessfully");
          this.partnerService.setCurrentPartnerId(campaign.partner.id);
        },
        error: () => {
          this.saving = false;
          this.notificationService.error("shared.changesCouldNotBeSaved");
        },
      });
  }

  protected formatShortUrl(): void {
    if (this.campaign.partner.shortUrl) {
      this.campaign.partner.shortUrl = this.urlFormatterService.formatUrl(
        this.campaign.partner.shortUrl,
      );
    }
  }

  public setAsDefault(address: Address) {
    this.campaign.partner.defaultAddressId = address.id;
    this.campaign.partner.addresses.map((address) => {
      address.isDefault = false;
    });
    address.isDefault = true;
  }

  public isThereAddressData(): boolean {
    const address = this.selectedAddress;
    if (!address) {
      return false;
    }
    return !!(
      address.streetAndNumber ||
      address.postalCode ||
      address.city ||
      address.country ||
      address.additionalInformation
    );
  }
}
